import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import axios from  'axios';

const DeviceForm = () => {

    let translations = window.translations;
    const [shipping, setShipping] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [shippingAddress, setShippingAddress] = useState('');
    const [payment, setPayment] = useState(null);
    const [newsletter, setNewsletter] = useState(null);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const handleShippingChange = (stats) => {
        setShipping(stats);
    }

    useEffect(() => {
        console.log({translations})
    }, [])

    const handleFormSubmit = (form, event) => {
        setSuccess(false)
        setError(false)
        let formData = new FormData(event.target);
        formData.shipping = shipping;
        formData.payment = payment;
        formData.newsletter = newsletter;
        formData.shippingAddress = shippingAddress;

        axios.post('/api/devis', formData).then((response) => {
           if(response.data.success) {
               setSuccess(response.data.message)
               form.reset()
               setTimeout(() => {
                   setSuccess(false);
               }, 10000)
           } else {
               setError(response.data.message)
               var element = document.querySelector("#error-form");
               element.scrollIntoView();


           }
        }).catch((err) => {
            setError(err.response.data.message)
            var element = document.querySelector("#error-form");
            element.scrollIntoView();
        });
    }


    let placeView, formView;


    const Select = React.forwardRef(({ onChange, onBlur, name, className }, ref) => (
        <>
            <select name={name} ref={ref} onChange={onChange} onBlur={onBlur} className={className}>
                <option value="">---</option>
                <option value="m">M.</option>
                <option value="mme">Mme.</option>
                <option value="ets">Ets.</option>
                <option value="epe">EPE</option>
                <option value="snc">SNC</option>
                <option value="eurl">EURL</option>
                <option value="sarl">SARL</option>
                <option value="spq">SPA</option>
                <option value="autre">AUTRE</option>
            </select>
        </>
    ));

    formView = (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="needs-validation" method="post" noValidate>
            <div className="row mb-3">
                {error ? (<div id='error-form' className='text-red-600 p-4 '>{error}</div>) : ''}
            </div>
            <div className="row mb-3">
                <div className="col-lg-3">
                    <div className="form-group">
                        <label className="form-label">Raison sociale <span className="required">*</span></label>
                        <Select {...register("raison", { required: true })} name="raison" className={`rounded-none border-2 border-gray-300 ${errors.raison?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`}/>
                        {errors.raison?.type === 'required' && (
                            <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                                Raison sociale est obligatoire
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="form-group">
                        <label className="form-label">Secteur D’activité <span className="required">*</span></label>
                        <div className="input-group has-validation">
                            <input {...register("activity", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.activity?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} name="activity" type="text" />
                            {errors.activity?.type === 'required' && (
                                <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                                    Secteur d’activité est obligatoire
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group mb-3">
                <label className="form-label">Adresse <span className="required">*</span></label>
                <input {...register("address", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.address?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} type="text" name="address"/>
                {errors.address?.type === 'required' && (
                    <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                        Adresse est obligatoire
                    </div>
                )}
            </div>
            <div className="row mb-3">
                <div className="col-md">
                    <label className="form-label">Registre du Commerce (RC) <span className="required">*</span></label>
                    <input {...register("rc", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.rc?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} type="text" name="rc" />
                    {errors.rc?.type === 'required' && (
                        <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                            Registre du commerce est obligatoire
                        </div>
                    )}
                </div>
                <div className="col-md">
                    <label className="form-label">Numéro d'identification Fiscal (NIF) <span className="required">*</span></label>
                    <input {...register("nif", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.nif?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} type="text" />
                    {errors.nif?.type === 'required' && (
                        <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                            Numéro d'identification fiscal est obligatoire
                        </div>
                    )}
                </div>
                <div className="col-md">
                    <label className="form-label">Numéro d'Article (AI)</label>
                    <input className="border-2 border-gray-300 form-control" type="text" name="ai"/>
                </div>
            </div>
            <div className="form-group mb-3">
                <label className="form-label">Nom et Prénom de personne à contacter</label>
                <input className="border-2 border-gray-300 form-control" type="text" name="name"/>
            </div>

            <div className="row mb-3">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Mobile <span className="required">*</span></label>
                        <input {...register("mobile", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.mobile?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} type="text" name="mobile"/>
                        {errors.mobile?.type === 'required' && (
                            <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                                Mobile est obligatoire
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Téléphone</label>
                        <input className="border-2 border-gray-300 form-control" type="text" />
                    </div>
                </div>
            </div>

            <div className="form-group mb-3">
                <label className="form-label">Email <span className="required">*</span></label>
                <input {...register("email", { required: true, maxLength: 255 })} className={`border-2 border-gray-300 ${errors.email?.type === 'required' && 'border-rose-600 text-slate-500 focus:outline-none focus:ring focus:ring-rose-200 focus:border-rose-500 dark:text-slate-400 dark:placeholder:text-slate-600 dark:bg-slate-900 dark:border-rose-500 dark:focus:ring-rose-900 dark:focus:border-rose-600'} form-control`} type="email" name="email" />
                {errors.email?.type === 'required' && (
                    <div className="text-rose-600 mt-2 dark:text-rose-500 text-sm">
                        Email est obligatoire
                    </div>
                )}
            </div>
            <div className="d-flex flex-column mb-3">
                <label className="form-label">Livraison <span className="required">*</span></label>
                <div className="form-group">
                    <div className="form-check form-check-inline">
                        <input onClick={event => setShipping(true)} defaultChecked={shipping} className="form-check-input" type="radio" name="shipping" id="radioShippingRadio1"
                               value="avec" />
                        <label className="form-check-label" htmlFor="radioShippingAvec">Avec</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input onClick={event => setShipping(false)} defaultChecked={shipping} className="form-check-input" type="radio" name="shipping" id="radioShippingRadio2"
                               value="sans" />
                        <label className="form-check-label" htmlFor="radioShippingSans">Sans</label>
                    </div>
                </div>
            </div>
            {shipping ? (
                <div className="form-group mb-3">
                    <label className="form-label">Lieu</label>
                    <input onChange={event => setShippingAddress(event.target.value)} className="form-control" type="text" name="lieu"/>
                </div>
            ):  ''}
            <div className="d-flex flex-column mb-6">
                <label className="form-label">Paiement</label>
                <div className="form-group">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="payment" id="virement"
                               value="transfer" onChange={event => setPayment('virement')}/>
                        <label className="form-check-label"  htmlFor="virement">Virement</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="payment" id="cheque"
                               value="cheque" onChange={event => setPayment('cheque')}/>
                        <label className="form-check-label" htmlFor="cheque">Chèque de banque</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="payment" id="cash"
                               value="cash" onChange={event => setPayment('cash')}/>
                        <label className="form-check-label" htmlFor="cheque">Cash</label>
                    </div>
                </div>
            </div>
            <div className="form-check mb-3">
                <input className="form-check-input" name="newsletter" type="checkbox" value="" id="flexCheckDefault" onChange={event => setNewsletter(event.target.value)}/>
                <label className="form-check-label text-sm" htmlFor="flexCheckDefault">
                    J’accepte de recevoir les offres, la newsletter par email
                </label>
            </div>

            <div className="form-group">
                <input type="submit" value="Créer mon compte" />
            </div>
        </form>
    )


    if(success) {
        formView = (
            <div>
                <p>{success}</p>
            </div>
        )
    }


  return (
      <div>
          <div>
              {formView}
          </div>

      </div>
  );
}

export default DeviceForm;

if (document.getElementById('device-form')) {
    ReactDOM.render(<DeviceForm/>, document.getElementById('device-form'));
}

