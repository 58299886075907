import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ProductsRepository from "../repositories/ProductsRepository";

const Products = ({url}) => {

    const [products, setProducts] = useState(null);
    const [productIndex, setProductIndex] = useState(null);

    const getProductsList = async () => {
        const result = await ProductsRepository.getAll();
        setProducts(result);
    }

    const handleShowSubItems = (event, index) => {
        event.preventDefault();
        if(productIndex === index) {
            setProductIndex(null)
        } else {
            setProductIndex(index)
        }

    }

    const handleHideSubItems = (event, index) => {
        event.preventDefault();
        if(productIndex === index) {
            setProductIndex(null)
        }

    }

    let productsView;
    if (products) {

        productsView = Object.entries(products).map((item, index) => {

            let productSubView = (
                <div className="p-4 bg-white border mb-2">
                    <div className="d-block text-center">
                        <a href="/demande-devis" className="custom-btn">Demande Devis</a>
                        <a href="tel:0668761634" className="custom-btn w-full ml-2">
                            <i className="lni lni-phone"></i>
                        </a>
                    </div>
                </div>
            );

            if(item[1].products.length) {
                productSubView = (
                    <div className="p-4 bg-white border mb-2">
                        <h2 className='text-xl font-bold mb-2'><a href="" onClick={event => handleShowSubItems(event, index)} className='text-red-500'>{item[0]}</a></h2>
                        <div className="flex flex-col h-full items-center">
                            <ul className="w-full h-48 overflow-y-auto">
                                {item[1].products.map((subItem, index) => {
                                    return (
                                        <li className='text-sm' key={index}>{subItem}</li>
                                    )
                                })}
                            </ul>
                            <div className="bloc text-center mt-4 space-x-2">
                                <a href="/demande-devis" className="py-2 px-4 text-sm border-2 border-red-500 bg-red-500 hover:bg-red-600 hover:border-red-600 hover:no-underline text-white hover:text-grey-700">Demande Devis</a>
                                <a href="tel:0668761634" className="py-2 px-4 text-sm border-2 border-red-500 bg-red-500 hover:bg-red-600 hover:border-red-600 hover:no-underline text-white hover:text-grey-700">
                                    <i className="lni lni-phone"></i>
                                </a>
                            </div>
                        </div>

                    </div>
                )
            }

            return (
                <div key={index} className='card-wrapper col-lg-4 col-md-6' onMouseEnter={event => handleShowSubItems(event, index)} onMouseLeave={event => handleHideSubItems(event, index)}>
                    <div
                         className={`${index === productIndex ? 'open-card' : ''} card-content`}>
                        <div style={{backgroundImage: 'url(' + item[1].image +')', backgroundColor: 'rgba(255,255,255,1)', backgroundBlendMode: 'luminosity'}} className='bg-cover bg-no-repeat relative shadow card-front h-64 mb-4'>
                            <div className='inner'>
                                {/*<button onClick={event => handleShowSubItems(event, index)} className='absolute z-10 inset-y-0 right-0 w-16 flex items-center justify-center text-white'>*/}
                                {/*    <i className="lni lni-arrow-right"></i>*/}
                                {/*</button>*/}
                                <a href="#" onClick={event => handleShowSubItems(event, index)} className={`flex justify-start items-start text-decoration-none absolute z-10 inset-x-0 bottom-0 font-bold text-white text-xl`}>
                                    <span className="text-decoration-none p-4 text-white">
                                        {item[0]}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className={` card-back`}>
                            <div className='inner'>
                                { productSubView }
                            </div>
                        </div>
                    </div>
                </div>


            )
        })
    }

    useEffect(() => {

        getProductsList();

    }, [])

    return (
        <>
            {productsView}
        </>
    )
}

export default Products;


if (document.getElementById('product-list')) {
    ReactDOM.render(<Products/>, document.getElementById('product-list'));
}
