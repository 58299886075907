import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from "react-dom";

const FormAjax = ({ children }) => {
    const [formVisible, setFormVisible] = useState(true);
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);
    const [message, setMessage] = useState('')

    useEffect(() => {

        const form = document.getElementById('form-ajax');

        const handleSubmit = async (event) => {
            event.preventDefault();
            const formData = new FormData(form);

            const queryParams = new URLSearchParams();
            for (const [key, value] of formData) {
                queryParams.append(key, value);
            }

            await sendData(form, queryParams);
        };

        form.addEventListener('submit', handleSubmit);

        // Cleanup function
        return () => {
            form.removeEventListener('submit', handleSubmit);
        };
    }, []);

    const sendData = async (form, queryParams) => {

        console.log(form.action)
        try {

            const response = await fetch(form.action, {
                method: form.method || 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest', // Important for Laravel to recognize as AJAX
                },
                body: queryParams,
            });

            if (response.ok) {
                const responseData = await response.json();
                setMessage(responseData.message)
                setFormVisible(false); // Hide the form on success
            } else if (response.status === 422) {
                const errorData = await response.json();
                setErrors(errorData.errors); // Set the validation errors
                displayErrors(errorData.errors);

            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const displayErrors = (errors) => {
        // Clear existing errors
        const existingErrors = document.querySelectorAll('.form-error');
        existingErrors.forEach(el => el.remove());

        const existingInputError = document.querySelectorAll('.input-has-error');
        existingInputError.forEach(el => el.className = el.className.replace('input-has-error', ''));

        let firstErrorInput = null;

        // Insert new errors
        for (const [fieldName, errorMessages] of Object.entries(errors)) {
            const input = document.getElementsByName(fieldName)[0];
            if (input) {
                if (!firstErrorInput) firstErrorInput = input; // Store the first input with error

                input.className = input.className + ' input-has-error'
                const errorSpan = document.createElement('span');
                errorSpan.className = 'form-error';
                errorSpan.textContent = errorMessages.join(', ');
                input.parentNode.insertBefore(errorSpan, input.nextSibling);
            }
        }

        // Focus and scroll to the first input with error
        if (firstErrorInput) {
            firstErrorInput.focus();
            firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div>
            <>
                <div className='relative' dangerouslySetInnerHTML={{ __html: children }} ref={formRef} />
                {!formVisible && (
                    <div className='absolute backdrop-blur left-0 top-0 bg-black/50 right-0 bottom-0 flex items-center justify-center'>
                        <div className="flex p-4 text-center flex-col justify-center items-center space-y-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 fill-current text-white" xmlSpace="preserve" viewBox="0 0 512 512"><path d="M497.36 69.995c-7.532-7.545-19.753-7.558-27.285-.032L238.582 300.845l-83.522-90.713c-7.217-7.834-19.419-8.342-27.266-1.126-7.841 7.217-8.343 19.425-1.126 27.266l97.126 105.481a19.273 19.273 0 0 0 13.784 6.22c.141.006.277.006.412.006a19.317 19.317 0 0 0 13.623-5.628L497.322 97.286c7.551-7.525 7.564-19.746.038-27.291z"/><path d="M492.703 236.703c-10.658 0-19.296 8.638-19.296 19.297 0 119.883-97.524 217.407-217.407 217.407-119.876 0-217.407-97.524-217.407-217.407 0-119.876 97.531-217.407 217.407-217.407 10.658 0 19.297-8.638 19.297-19.296C275.297 8.638 266.658 0 256 0 114.84 0 0 114.84 0 256c0 141.154 114.84 256 256 256 141.154 0 256-114.846 256-256 0-10.658-8.638-19.297-19.297-19.297z"/></svg>
                            <div className='z-10 text-white'>{message}</div>
                        </div>
                    </div>
                )}
            </>

        </div>
    );
};

export default FormAjax;

const el = document.getElementById('form-ajax');
if (el) {
    ReactDOM.render(<FormAjax children={el.innerHTML}/>, el)
}
// if (el) {
//     ReactDOM.render(<FormAjax children={el.innerHTML}/>, el);
//     el.addEventListener('submit', event => {
//         event.preventDefault();
//         const formData = new FormData(el);
//
//         const queryParams = new URLSearchParams();
//         for (const [key, value] of formData) {
//             queryParams.append(key, value);
//         }
//
//         sendData(queryParams);
//     })
//
//     const sendData = async (queryParams) => {
//         try {
//             const response = await fetch(`${el.action}?${queryParams}`, {
//                 method: 'POST'
//             });
//             const responseData = await response.json();
//             console.log(responseData); // Process the response data
//         } catch (error) {
//             console.error('Error:', error);
//         }
//     }
// }


