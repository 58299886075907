import {Repository} from "./Repository";

class ProductsRepository {

    /**
     * Get All products items
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getAll() {
        const endpoint = `/products`;

        return await Repository.get(`${endpoint}`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    }
}

export default new ProductsRepository();
