import ReactDOM from "react-dom";
import React, {useCallback, useEffect, useState} from "react";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";
import t from "../helpers/translation";

const FlyerDownload = () => {

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.MIX_RECAPTCHA_V3}>
            <FlyerDownloadForm />
        </GoogleReCaptchaProvider>
    );
}

function validateEmail(email) {
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
}

const FlyerDownloadForm = () => {

    const [newsletter, setNewsletter] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [recaptchaToken, setToken] = useState('');
    const [loading, setLoading] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [validated, setValidated] = useState(false)


    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors({})
        setLoading(true)
        setSuccess(false)

        if(!email) {
            setErrors({
                email: [t('Email is required')]
            })
            setLoading(false)
        }



        let re = /\S+@\S+\.\S+/;

        if(! re.test(email)) {
            setErrors({
                email: [t('Email is invalid')]
            })
            setLoading(false)
        }


        if(email && re.test(email)) {


            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }


            executeRecaptcha('download_flyer').then((token) => {
                console.log({token})
                const data = {
                    email,
                    'recaptchaToken': token,
                    newsletter
                }
                if(email) {
                    axios.post('/flyer', data).then((response) => {
                        console.log({response})
                        setLoading(false)
                        setSuccess(true)
                        setEmail('')
                    }).catch((err) => {
                        console.log({err})
                        setLoading(false)
                        if(err.response.data !== undefined && err.response.data.errors !== undefined) {
                            setErrors(err.response.data.errors)
                        }
                    })

                }
            });
        }
    }

    let buttonView, errorView;


    const handleEmailHasChanged = (event) => {
        const value = event.target.value;
        setEmail(value)

        if (validateEmail(value)) {
            console.log('valide')
            setValidated(true);
        } else {
            setValidated(false);
        }

    }

    buttonView = (
        <span>{t('TÉLÉCHARGER')} <i className="lni lni-arrow-right"></i></span>
    )

    if(Object.entries(errors).length) {
        errorView = (
            <div className='bg-red-500 my-4 px-4 py-2'>
                {Object.entries(errors).map((err, i) => {
                    return (
                        <p key={i} className='text-sm text-black'>{err[1][0]}</p>
                    )
                })}
            </div>
        );
    }

    if(loading) {
        buttonView = (
            <span className="flex items-center justify-center">
                <svg className="spinner" viewBox="0 0 64 64" fill="#2D2D2D">
                <path d="M34.286 14.446a2.286 2.286 0 0 1-4.572 0V2.743a2.286 2.286 0 0 1 4.572 0v11.703z"></path>
                <path
                    d="M42.757 17.94a2.286 2.286 0 1 1-3.96-2.285L44.65 5.52a2.286 2.286 0 1 1 3.96 2.286L42.756 17.94z"
                    fill="none"></path>
                <path
                    d="M48.345 25.202a2.286 2.286 0 0 1-2.286-3.959l10.136-5.851a2.286 2.286 0 0 1 2.286 3.959l-10.136 5.851z"
                    fillOpacity=".1"></path>
                <path d="M49.554 34.286a2.286 2.286 0 0 1 0-4.572h11.703a2.286 2.286 0 0 1 0 4.572H49.554z"
                      fillOpacity=".15"></path>
                <path
                    d="M46.06 42.757a2.286 2.286 0 1 1 2.285-3.96L58.48 44.65a2.286 2.286 0 1 1-2.286 3.96L46.06 42.756z"
                    fillOpacity=".25"></path>
                <path
                    d="M38.798 48.345a2.286 2.286 0 0 1 3.959-2.286l5.851 10.136a2.286 2.286 0 0 1-3.959 2.286l-5.851-10.136z"
                    fillOpacity=".35"></path>
                <path d="M29.714 49.554a2.286 2.286 0 0 1 4.572 0v11.703a2.286 2.286 0 0 1-4.572 0V49.554z"
                      fillOpacity=".45"></path>
                <path
                    d="M21.243 46.06a2.286 2.286 0 1 1 3.96 2.285L19.35 58.48a2.286 2.286 0 1 1-3.96-2.286l5.852-10.136z"
                    fillOpacity=".55"></path>
                <path
                    d="M15.655 38.798a2.286 2.286 0 0 1 2.286 3.959L7.805 48.608a2.286 2.286 0 0 1-2.286-3.959l10.136-5.851z"
                    fillOpacity=".65"></path>
                <path d="M14.446 29.714a2.286 2.286 0 0 1 0 4.572H2.743a2.286 2.286 0 0 1 0-4.572h11.703z"
                      fillOpacity=".7"></path>
                <path
                    d="M17.94 21.243a2.286 2.286 0 1 1-2.285 3.96L5.52 19.35a2.286 2.286 0 1 1 2.286-3.96l10.136 5.852z"
                    fillOpacity=".85"></path>
                <path
                    d="M25.202 15.655a2.286 2.286 0 0 1-3.959 2.286L15.392 7.805a2.286 2.286 0 0 1 3.959-2.286l5.851 10.136z"></path>
            </svg>
            </span>
        )
    }

    if(success) {
        buttonView = (
            <span className="flex items-center justify-start">
                <svg className='w-8 h-8 fill-gray-500' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                     x="0px" y="0px"
                     viewBox="0 0 98.5 98.5" enableBackground="new 0 0 98.5 98.5" xmlSpace="preserve">
                  <path className="checkmark" fill="none" strokeWidth="8" strokeMiterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
                    C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
                </svg>
                <span className='ml-4'>{t('Message envoyer')}</span>
            </span>
        )
    }


    return (

        <form action="" onSubmit={event => handleFormSubmit(event)} className="flyer-form bg-transparent p-0 shadow-none">
            {errorView}
            <div className="form-group my-4">
                <label htmlFor="contact-email" className="mb-2 text-sm text-gray-100">{t('Votre email')}</label>
                <input required onChange={handleEmailHasChanged} defaultValue={email} className="text-gray-800" name="flyer-email" type="email" id="flyer-email" />
            </div>
            <div className="form-check mb-3">
                <input className="form-check-input" defaultValue={newsletter} name="flyer-newsletter" type="checkbox" id="flexCheckDefault" onChange={event => setNewsletter(! newsletter)}/>
                <label className="form-check-label text-sm" htmlFor="flexCheckDefault">
                    {t("J’accepte de recevoir les offres, la newsletter par email")}
                </label>
            </div>
            <div id='recaptha-v3'></div>
            <button disabled={!validated} type='submit' className="button disabled:cursor-not-allowed disabled:opacity-90 w-64 px-4 flex items-center justify-center">
                {buttonView}
            </button>
        </form>
    );
}


if (document.getElementById('flyer-download')) {
    ReactDOM.render(<FlyerDownload />, document.getElementById('flyer-download'));
}
