require('./components/Products');
require('./components/DeviceForm');
require('./components/FormAjax');
require('./components/FlyerDownload');
import MicroModal from 'micromodal';  // es6 module
MicroModal.init();

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
document.querySelectorAll('.navbar .site-menu ul li a').forEach(link => {
    link.addEventListener('click', function(e) {
        e.preventDefault(); // Prevent default anchor behavior


        console.log('Cliked')
        const targetId = this.getAttribute('href');
        const targetSection = document.querySelector(targetId);

        if (targetSection) {

            // Scroll to the target section
            targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Update URL hash
            history.pushState(null, null, targetId);
        }

    });
});

// Update active state on scroll



function handleScroll() {
    let currentSection = '';
    document.querySelectorAll('section').forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (pageYOffset >= (sectionTop - sectionHeight / 3)) {
            if (section.getAttribute('id')) {
                currentSection = section.getAttribute('id');
                console.log({currentSection})
            }

        }
    });

    document.querySelectorAll('.navbar .site-menu ul li a').forEach(link => {
        console.log({currentSection})
        link.classList.remove('active');
        if (currentSection) {
            console.log({currentSection})
            if (link.getAttribute('href') === '#' + currentSection) {
                link.classList.add('active');
            }
        }

    });
}

var debouncedHandleScroll = debounce(handleScroll, 100);

window.addEventListener('scroll', debouncedHandleScroll);

