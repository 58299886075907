import axios from 'axios';
const baseDomain = 'https://bergansteel.com'; // change it from dev to production
const baseDevDomain = 'http://127.0.0.1:8000'; // change it from dev to production

export const customHeaders = {
    Accept: 'application/json',
};

export const baseUrl = process.env.NODE_ENV === 'production' ? `${baseDomain}` : `${baseDevDomain}`;

console.log({baseUrl})

export const Repository = axios.create({
    baseUrl,
    headers: customHeaders,
});

